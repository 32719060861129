<template>
  <v-dialog id="dialog" v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" text class="primary--text">
        <v-icon class="mr-1">add_box</v-icon>
        Whitelist User
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Whitelist User</v-card-title>
      <v-card-text>
        <form v-on:submit.prevent="create()">
          <v-select
              :items="userTypes"
              label="Authentication Type"
              v-model="user.userType"            
              item-text="name"
              item-value="id"
              solo
            ></v-select>
          <v-select
              v-if="user.userType == 'externalUser'"
              :items="providers"
              label="Provider Type"
              v-model="user.providerName"            
              item-text="name"
              item-value="id"
              solo
            ></v-select>

          <v-text-field v-if="user.userType === 'externalUser' && user.providerName === 'github'" required label="Username" v-model="user.username" />
          <v-text-field v-else required label="Email" v-model="user.email" />          
          
          <v-btn v-show="false" hidden type="submit">
          </v-btn>
        </form>
        <div v-if="error">
          <v-alert error type="warning">
            {{ error }}
          </v-alert>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn text v-on:click.native="dialog = false" class="primary--text">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn text :disabled="loading" :loading="loading" v-on:click.native.stop="create()" class="primary">Add User To Whitelist</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="stylus">
  .addbtn
    margin: 0px
    top: auto
    right: 20px
    bottom: 20px
    left: auto
    position: fixed
    z-index: 1
</style>

<script>

  export default {
    data () {
      return {
        dialog: false,
        userTypes: [
          {
            name: "Internal",
            id: "internalUser"
          },
          {
            name: "External (OAuth)",
            id: "externalUser"
          }
        ],
        providers: [
          {
            name: "Google",
            id: "google"
          },
          {
            name: "GitHub",
            id: "github"
          }
        ],
        user: {
          email: '',
          username: '',
          providerName: 'google',
          userType: 'externalUser'
        },

      }
    },

    computed: {
      loading() {
        return this.$store.state.admin.loading
      },
      error() {
        return this.$store.state.admin.error
      }
    },

    methods: {
      create () {
        let payload = {
          userType: this.user.userType
        }
        // Based on user type, we set other fields
        if (this.user.userType === 'externalUser') {
          payload.providerName = this.user.providerName
        }

        // Username is only used by github, in other cases we expect email
        if (this.user.userType === 'externalUser' && this.user.providerName === 'github') {
          payload.username = this.user.username
        } else {
          payload.email = this.user.email
        }

        this.$store.dispatch('CreateWhitelistedUser', payload).then(() => {
          if (this.error != null) {
            // got error, don't reset form, let user
            // fix the mistake :S
            return
          }
          this.$store.dispatch('Notify', `User whitelisted`)
          this.$store.dispatch('ListWhitelistedUsers')
          this.resetForm()
        })
      },

      resetForm: function () {
        // closing dialog
        this.dialog = false

        // resetting form
        this.user.email = ''
        this.user.username = ''
        this.user.providerName = 'google'
        this.user.userType = 'externalUser'
      }
    }
  }
</script>
