<template>
  <div>
    <v-layout>
      <v-btn icon class="primary--text mt-1 mr-2" :to="`/`" router exact>
        <v-icon>keyboard_backspace</v-icon>
      </v-btn>

      <page-title>Whitelisted Users</page-title>

      <div class="ml-4 mt-1 mb-2">
        <v-btn class="primary--text mr-3" text @click="refresh()">
          <v-icon class="mr-1">refresh</v-icon>
          Refresh
        </v-btn>        
        <whitelisted-user-create></whitelisted-user-create>
        <!-- <create-user-access-key></create-user-access-key> -->
      </div>
    </v-layout>

    <v-layout>
      <page-subtitle
        >Create, view and manage system whitelisted users. These users can register
        while the registration is restricted.</page-subtitle
      >
    </v-layout>

    <!-- delete confirmation modal -->
    <v-dialog v-model="modal" max-width="500px">
      <v-card>
        <v-card-title>Confirmation Required</v-card-title>
        <v-card-text>
          Are you sure want to delete whitelisted user '{{ removingItem.id }}'?
        </v-card-text>
        <v-card-actions>
          <v-btn text v-on:click.native="modal = false" class="primary">Cancel</v-btn>
          <v-spacer>
          </v-spacer>
          <v-btn v-on:click.native="removeConfirm(removingItem)" text class="primary--text">
            Remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-layout class="mt-2">
      <div class="tbcard">
        <div v-if="!loading && whitelistedUsers.length === 0">
          <v-container fill-height align-content-space-around>
            <v-layout row justify-center>
              <v-flex xs4 class="text-md-center mt-5">
                <span class="headline">No Whitelisted Users</span>
                <span class="subheading">
                  <page-subtitle class="text-md-center">
                    Whitelist user emails/usernames so they can join Synpse. Invite by
                    email regular and Google OAuth users or by username GitHub OAuth users.
                  </page-subtitle>
                </span>
              </v-flex>
            </v-layout>
          </v-container>
        </div>

        <v-card v-else-if="whitelistedUsers.length > 0" outlined>
          <v-data-table
            :key="tableKey"
            :headers="headers"
            :items="whitelistedUsers"
            :search="e3"
          >
            <template v-slot:[`item.id`]="{ item }">
                {{ item.id }}
            </template>

            <template v-slot:[`item.createdAt`]="{ item }">
              <v-tooltip top :key="item.id">
                <template v-slot:activator="{ on: tooltip }">
                  <span v-on="{ ...tooltip }">
                    {{ item.createdAt | ago }}
                  </span>
                </template>
                <span>{{ item.createdAt | date }}</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-menu class="items" top left transition="v-slide-y-transition">
                <template v-slot:activator="{ on: on }">
                  <v-btn
                    icon
                    v-on="on"
                    :class="
                      $store.state.theme === 'light' ? 'secondary--text' : ''
                    "
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list dense>
                  <v-list-item @click="removeIntent(item)">
                    <v-list-item-title>delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </v-layout>
  </div>
</template>

<style lang="stylus">
.tbcard {
  width: 100%;
  display: block;
}
</style>

<script>
import PageSubtitle from '../PageSubtitle';
import PageTitle from '../PageTitle';
import WhitelistedUserCreate from './WhitelistedUserCreate.vue';

export default {
  components: {
    PageSubtitle,
    PageTitle,
    WhitelistedUserCreate,
    // CreateUserAccessKey,
  },
  data() {
    return {
      tableKey: '',
      e3: null,
      headers: [
        { text: 'ID', align: 'left', value: 'id', sortable: true },
        { text: 'Email', value: 'email', align: 'left' },
        { text: 'Username', value: 'username', align: 'left' },
        { text: 'User Type', value: 'userType', align: 'left' },
        { text: 'Provider', value: 'providerName', align: 'left' },
        { text: 'Registered', value: 'registered', align: 'left' },
        { text: 'Created', value: 'createdAt', align: 'left' },
        { text: '', value: 'actions', sortable: false, align: 'right' }
      ],
      modal: false,
      removingItem: {
        id: '',
        email: ''
      }
    }
  },

  computed: {
    loading() {
      return this.$store.state.admin.loading
    },
    whitelistedUsers() {
      return this.$store.state.admin.whitelistedUsers
    },
  },

  mounted() {
    this.refresh()
  },

  methods: {
    refresh() {
      this.$store.dispatch('ListWhitelistedUsers')
    },
    removeIntent (removingItem) {
      // assigning removing input val
      this.removingItem = removingItem
      this.modal = true
    },
    removeConfirm () {
      this.$store.dispatch('DeleteWhitelistedUser', this.removingItem).then(() => {
        this.refresh()
        // closing confirmation modal
        this.modal = false
      })
    }
  }
}
</script>